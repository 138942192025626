import { EyeFilled, EyeOutlined } from "@ant-design/icons";
import { Slider, SliderSingleProps, Tooltip } from "antd";
import React from "react";
import "./WallAndFloorOpacityHandler.scss";

const WallAndFloorOpacityHandler = (props: { wallAndFloorOpacity: Number; setWallAndFloorOpacity: any }) => {
  const { wallAndFloorOpacity, setWallAndFloorOpacity } = props;

  const formatter: NonNullable<SliderSingleProps["tooltip"]>["formatter"] = (value) => {
    let percent = 100;
    if (value) percent = value * 100;
    else if (value === 0) percent = 0;
    return `${percent}%`;
  };

  return (
    <div className="d-flex flex-row align-items-center">
      <Tooltip title="Transparent">
        <EyeOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
      </Tooltip>
      <Slider
        data-testid="opacity-slider"
        className="w-36r"
        defaultValue={1}
        min={0}
        max={1}
        step={0.1}
        value={wallAndFloorOpacity as number}
        tooltip={{ formatter }}
        onChange={setWallAndFloorOpacity}
      />
      <Tooltip title="Opaque">
        <EyeFilled onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
      </Tooltip>
    </div>
  );
};

export default WallAndFloorOpacityHandler;
